import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Link } from 'react-router-dom';
import Home from "./home";
import NewMenu from "./menu";
import Box from '@mui/material/Box';

import TabsCadastrosIniciais from '../TabCadastrosIniciais';
import TabCadastrosInadimplente from "../TabCadastrosInadimplentes";
import TabGestaoInadimplentes from '../TabsGestaoInadimplentes';
import TabGestaoPagamentos from "../TabGestaoPagamentos";
import ConsultaInadimplente from "../ConsultaInadimplente";
import RelatoriosFinanceiros from "../RelatoriosFinanceiros";
import ChartHome from "../ChartHome";
import Judiciais from '../TabsGestaoJudiciais';
import ImpressaoDocumentos from '../TabImpressaoDocumentos';




/*
import CadastroInadimplente from "../TabCadastroInadimplente/CadastroInadimplente";
import CadastroDebitoInadimplente from "../TabCadastroInadimplente/CadastroDebitoInadimplente";
*/

const Router = () => {
  const rootElement = document.getElementById("root");

  return (
 
    <div>

      <BrowserRouter>

        <Routes> 

          <Route element={<Box sx={{ width: "100%", display: 'flex' }} > < NewMenu /> < ChartHome /></Box>} path="/" exact />
          <Route element={<Box sx={{ width: "100%", display: 'flex' }}>< NewMenu /> < TabsCadastrosIniciais /></Box>} path="/TabsCadastrosIniciais" />
          <Route element={<Box sx={{ width: "100%", display: 'flex' }}>< NewMenu />< TabCadastrosInadimplente /></Box>} path="/TabCadastrosInadimplente" />
          <Route element={<Box sx={{ width: "100%", display: 'flex' }}>< NewMenu />< TabGestaoInadimplentes /></Box>} path="/TabGestaoInadimplentes" />
          <Route element={<Box sx={{ width: "100%", display: 'flex' }}>< NewMenu />< RelatoriosFinanceiros /></Box>} path="/RelatoriosFinanceiros" />
          
          <Route element={<Box sx={{ width: "100%", display: 'flex' }}>< NewMenu />< ImpressaoDocumentos /></Box>} path="/TabImpressaoDocumentos" />
          <Route element={<Box sx={{ width: "100%", display: 'flex' }}>< NewMenu />< TabGestaoPagamentos /></Box>} path="/TabGestaoPagamentos" />
          <Route element={<Box sx={{ width: "100%", display: 'flex' }}>< NewMenu />< Judiciais /></Box>} path="/Judiciais" />

          <Route element={<Box sx={{ width: "100%", display: 'flex' }}>< NewMenu />< ConsultaInadimplente /></Box>} path="/ConsultaInadimplente" />

        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default Router;