
import { FormControl } from '@mui/base/FormControl';
import Button from '@mui/material/Button';
import { TextField, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { StyledEngineProvider } from '@mui/material/styles';
import React, { useState, useEffect } from 'react'
import DataGridInadimplente from '../datagrids/DataGridCadastroInadimplente';
import Divider from '@mui/material/Divider';
import SendIcon from '@mui/icons-material/Send';
import Card from '@mui/material/Card';
import Inadimplente from '../../DTO/Inadimplente';
import CrudInadimplente from '../../Service/CrudInadimplente';
import Autocomplete from '@mui/material/Autocomplete';
import ValidaTextField from '../Helper/ValidaTextField.js';
import CreateFolderIDInadimplente from '../googleApi/Cria Pastas /createFolderIDInadimplente.js';
import BusinessIcon from '@mui/icons-material/Business';

import AccountCircle from '@mui/icons-material/AccountCircle';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import InputAdornment from '@mui/material/InputAdornment';
import MailIcon from '@mui/icons-material/Mail';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';




const Listacolegio = [
  { label: 'Colegio Santa Marina', id: 1 },
  { label: 'Colegio Augusto Maia', id: 2 },

]

const estadosCivil = [
  { label: 'solteiro(a)', id: 1 },
  { label: 'casado(a)', id: 2 },
  { label: 'divorciado(a)', id: 3 },
  { label: 'viuvo(a)', id: 4 },
  { label: 'estado civil ignorado', id: 5 }
];


const nacionalidade = [
  { label: 'brasileiro(a)', id: 1 },
  { label: 'Estrangeiro(a)', id: 2 },
  { label: 'nacionalidade ignorada)', id: 3 },


]

const foro = [
  { label: 'REGIONAL DO TATUAPÉ', id: 1 },
  { label: 'REGIONAL DE ITAQUERA', id: 2 },
  { label: 'REGIONAL PENHA DE FRANÇA', id: 3 },
  { label: 'REGIONAL DE SÃO MIGUEL PAULISTA', id: 4 },
  { label: 'REGIONAL DE PINHEIROS', id: 5 },
  { label: 'REGIONAL DO JABAQUARA', id: 6 },
  { label: 'foro ignorada)', id: 7 },
]
const genero = [
  { label: 'feminino', id: 1 },
  { label: 'masculino', id: 2 },


]
const cidade = [
  { label: 'São Paulo', id: 1 },
  { label: 'Rio de Janeiro', id: 2 },
  { label: 'cidade ignorada)', id: 3 },


]
const estado = [
  { label: 'São Paulo', id: 1 },
  { label: 'Rio de Janeiro', id: 2 },
  { label: 'estado ignorado)', id: 3 },


]
const inadimplente = new Inadimplente();
const crudInad = new CrudInadimplente();
const createFolderIDInadimplente = new CreateFolderIDInadimplente;

function validaEmail(email) {
  const validacao = new ValidaTextField("");

  validacao.validacaoEmail(email)
  inadimplente.setemail(email);

}



function limpaform() {
  Array.from(document.querySelectorAll("input")).forEach(
    input => (input.value = "")

  );
  window.location.reload();

}


function getSiglaColegio(colegio) {
  const siglasEspeciais = {
    "COLÉGIO EVANGÉLICO PASTOR JOSÉ QUIRINO LTDA": "C.J.Q",
    "COLÉGIO CAMPOS PIAGET LTDA": "C.C.P",
    "COLÉGIO EVANGÉLICO DE ITAQUERA": "C.E.I",
    "ESCOLA SANTA MARINA": "E.S.M",
    "COLÉGIO AUGUSTO MAIA LTDA": "C.A.M",
    "ESCOLA DE EDUCAÇÃO INFANTIL CAMPOS PIAGET": "E.C.P",
    "COLÉGIO EVANGÉLICO DA CIDADE TIRADENTES LTDA": "C.E.C.T"

  };

  // Se o colégio tiver uma sigla especial, retorna ela
  if (siglasEspeciais[colegio]) {
    return siglasEspeciais[colegio];
  }

  // Se não tiver sigla especial, segue a lógica anterior
  const palavras = colegio.split(' ');
  let sigla = '';
  palavras.forEach((palavra, index) => {
    sigla += palavra.charAt(0).toUpperCase();
  });
  return sigla;
}


function createFolderInRoot(id) {

  // Autenticação
  const accessToken = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;

  // SETA DADOS DA PASTA INADIMPLENTE
  let colegioSelecionado = document.getElementsByName('lblcolegio')[0].value;
  let sigla = getSiglaColegio(colegioSelecionado);
  console.log(sigla); // Saída: C.S.M. (para "Colegio Santa Marina")


  // Dados da pasta inadimplente
  let newFolderName = document.getElementsByName('responsavelFinanceiro')[0].value;

  let nomeInadimplente = document.getElementsByName('responsavelFinanceiro')[0].value;
  // Substitui espaços por pontos
  newFolderName = newFolderName.replace(/\s+/g, '.');
  // Combina a sigla do colégio com o nome da pasta
  newFolderName = sigla + '.' + newFolderName.toUpperCase();

  inadimplente.setnomeResponsavelFinanceiro(document.getElementsByName('responsavelFinanceiro')[0].value); // Nome da nova pasta


  //SETA DADOS PASTA ROOT
  const rootFolderId = document.getElementsByName('idRootInadimplente')[0].value;
  inadimplente.setidRootGoogleDrive(rootFolderId);
  // ID da pasta raiz

  //CRIA PASTA DO INADIMPLENTE
  const requestData = {
    mimeType: 'application/vnd.google-apps.folder',
    name: newFolderName,
    parents: [rootFolderId],
  };
  console.log(requestData);
  fetch('https://www.googleapis.com/drive/v3/files', {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(requestData),
  })
    .then((res) => res.json())
    .then((pastaGD) => {
      console.log("ID DA PASTA INADIMPLENTE");
      console.log(pastaGD);
      console.log(pastaGD.name);
      console.log(pastaGD.id);
      console.log(inadimplente);
      inadimplente.setpastaInadimplenteGoogleDrive(pastaGD.name);
      inadimplente.setidPastaInadimplenteGoogleDrive(pastaGD.id);
      inadimplente.setid(id);


      //CHAMA AQUI A API
      const crudescr = new CrudInadimplente();
      crudescr.incluiPastasInadimplenteAdvocaciaAPI(nomeInadimplente, inadimplente);
      alert("Pasta Criada com sucesso noo Google Drive!");
      window.location.reload();

    })
    .catch((error) => {
      console.error('Error:', error);
    });

}

/*
function CepSearch() {
  const [cep, setCep] = useState('');
  const [address, setAddress] = useState({});

  const fetchCepData = () => {
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then(response => response.json())
      .then(data => {
        setAddress(data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };
  inadimplente.setcep(address.cep);
  inadimplente.setendereco(address.logradouro);
  inadimplente.setbairro(address.bairro);
  inadimplente.setcidade(address.localidade);
  inadimplente.setestado(address.uf);

  const limpaCep = () => {
    setAddress("");
    setCep("");
  };


  return (
    <div>
      <TextField
        label="CEP"
        value={cep}
        onChange={(e) => setCep(e.target.value)}
        variant="standard"
        sx={{ width: '30%', marginTop: 1 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <BusinessIcon />
            </InputAdornment>
          ),
        }}
      />
      <Button variant="standard" onClick={fetchCepData}
        sx={{ marginTop: 2, marginLeft: 0, justifyContent: "center", width: "5%" }}
        color="success" endIcon={<SendIcon />}> </Button>
      <TextField
        label="Numero do Endereço"
        color='secondary'
        value={inadimplente.getnumero()}
        onChange={e => inadimplente.setnumero(e.target.value)}
        variant="standard"
        sx={{ width: '20%', marginLeft: 2, marginTop: 1 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <BusinessIcon />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label="Complemento"
        color='secondary'
        value={inadimplente.getcomplemento()}
        onChange={e => inadimplente.setcomplemento(e.target.value)}
        variant="standard"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <BusinessIcon />
            </InputAdornment>
          ),
        }}
        sx={{ width: '30%', marginLeft: 2, marginTop: 1 }}
      />

      <div>
        {address.cep && (
          <div><br />
            <h6>Informações do Endereço:</h6>
            <p>CEP: {address.cep}</p>
            <p>Logradouro: {address.logradouro}</p>
            <p>Bairro: {address.bairro}</p>
            <p>Cidade: {address.localidade}</p>
            <p>Estado: {address.uf}</p>

          </div>
        )}

      </div>
    </div>


  );
}*/




function CepSearch() {
  const [cep, setCep] = useState('');
  const [address, setAddress] = useState({});
  const [errorCep, setErrorCep] = useState(false);

  const fetchCepData = () => {
    if (cep.trim() === "") {
      setErrorCep(true); // Define erro se CEP estiver vazio
      return;
    }

    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then(response => response.json())
      .then(data => {
        if (data.erro) {
          setErrorCep(true); // Caso o CEP seja inválido
        } else {
          setAddress(data);
          setErrorCep(false); // Remove o erro se o CEP for válido

          // Atualiza os dados no objeto inadimplente
          inadimplente.setcep(data.cep);
          inadimplente.setendereco(data.logradouro);
          inadimplente.setbairro(data.bairro);
          inadimplente.setcidade(data.localidade);
          inadimplente.setestado(data.uf);
        }
      })
      .catch(error => {
        console.error('Erro na busca do CEP:', error);
        setErrorCep(true);
      });
  };

  const limpaCep = () => {
    setAddress({});
    setCep('');
    setErrorCep(false);
  };

  return (
    <div>
      {/* Campo CEP */}
      <TextField
        label="CEP"
        value={cep}
        onChange={(e) => {
          setCep(e.target.value);
          if (e.target.value.trim() !== "") {
            setErrorCep(false); // Remove o erro se o usuário começar a digitar
          }
        }}
        onBlur={() => {
          if (cep.trim() === "") {
            setErrorCep(true);
          }
        }}
        variant="standard"
        sx={{ width: '30%', marginTop: 1 }}
        error={errorCep}
        helperText={errorCep ? "O CEP é obrigatório e deve ser válido" : ""}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <BusinessIcon />
            </InputAdornment>
          ),
        }}
      />

      {/* Botão Buscar CEP */}
      <Button 
        variant="standard" 
        onClick={fetchCepData}
        sx={{ marginTop: 2, marginLeft: 0, justifyContent: "center", width: "5%" }}
        color="success" 
        endIcon={<SendIcon />}
      />

      {/* Campo Número do Endereço */}
      <TextField
        label="Número do Endereço"
        color="secondary"
        value={inadimplente.getnumero()}
        onChange={e => inadimplente.setnumero(e.target.value)}
        variant="standard"
        sx={{ width: '20%', marginLeft: 2, marginTop: 1 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <BusinessIcon />
            </InputAdornment>
          ),
        }}
      />

      {/* Campo Complemento */}
      <TextField
        label="Complemento"
        color="secondary"
        value={inadimplente.getcomplemento()}
        onChange={e => inadimplente.setcomplemento(e.target.value)}
        variant="standard"
        sx={{ width: '30%', marginLeft: 2, marginTop: 1 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <BusinessIcon />
            </InputAdornment>
          ),
        }}
      />

      {/* Informações do Endereço */}
      {address.cep && (
        <div><br />
          <h6>Informações do Endereço:</h6>
          <p>CEP: {address.cep}</p>
          <p>Logradouro: {address.logradouro}</p>
          <p>Bairro: {address.bairro}</p>
          <p>Cidade: {address.localidade}</p>
          <p>Estado: {address.uf}</p>
        </div>
      )}
    </div>
  );
}




export default function CadastroInadimplente() {

  const [colegios, setColegios] = useState([]); // Initialize with empty array
  const [idLogoGoogleDrive, setIdLogoGoogleDrive] = useState('');
  const [errorColegio, setErrorColegio] = useState(false);
  const [errorNomeResponsavel, setErrorNomeResponsavel] = useState(false);
  const [nomeResponsavel, setNomeResponsavel] = useState(inadimplente.getnomeResponsavelFinanceiro() || "");
  const [errorCep, setErrorCep] = useState(false);
  const [errorPastaGoogle, setErrorPastaGoogle] = useState(false);  

  useEffect(() => { 
    async function fetchEscritorios() {
      const colegiosData = await crudInad.buscacolegioAPI();
      console.log("colegiosData");
      console.log(colegiosData);

      if (colegiosData) {
        setColegios(colegiosData);
      }
    }
    fetchEscritorios();
  }, []); // Run once on component mount


  const validaCadastro = () => {
    let valido = true;
  
    if (!inadimplente.getcolegio()) {
      setErrorColegio(true);
      valido = false;
    }
  
    if (!inadimplente.getnomeResponsavelFinanceiro()) {
      setErrorNomeResponsavel(true);
      valido = false;
    }
  
    if (!inadimplente.getcep()) {
      setErrorCep(true);
      valido = false;
    }
  
    if (!idLogoGoogleDrive.trim()) {
      setErrorPastaGoogle(true);
      valido = false;
    }
  
    return valido;
  };
  
  
  
  async function cadastraInadimplente() {
    // createFolderIDInadimplente.CreateFolderButton();
  
    if (!validaCadastro()) {
      alert ( "Confira os campo : Colegio, Nome do Responsável Financeiro  e CEP")
      return;
    }
  
    try {
      const id = await crudInad.cadastraInadimplenteAPI(inadimplente);
      await createFolderInRoot(id);
      console.log("Inadimplente cadastrado com sucesso, ID:", id);
    } catch (error) {
        console.error("Erro ao cadastrar inadimplente:", error);
    }
  
  }
  

  return (

    <Box sx={{
      width: '99%',
      justifyContent: "Center",
      p: 0,
      background: "#f3f6f37d",

    }} >


      <Card variant="outlined" sx={{
        width: '100%',
        justifyContent: "left",
        flexDirection: 'row',
        display: 'flex',
      }} >

        <Box sx={{
          width: '50%',
          justifyContent: "left",
          position: "relative",
          flexGrow: 2

        }} >

          <FormControl defaultValue="">
            <Stack spacing={0} sx={{ marginLeft: 5, marginRight: 5, marginTop: 5, marginBottom: 3, align: "Center" }}>
              <h2>Cadastro Inadimplente</h2>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                color='secondary'
                options={colegios}
                onChange={(e, value) => {
                  if (value && value.label) {
                    inadimplente.setcolegio(value.label);  // Atualiza o valor do colegio
                    setErrorColegio(false);
                  } else {
                    setErrorColegio(true);  // Se não houver label, define o erro
                  }
                }}
                onBlur={(e) => {
                  const selected = colegios.find(item => item.label === e.target.value);
                  if (selected) {
                    console.log("Item ID:", selected.id);
                    console.log("Item Label:", selected.label);
                    console.log("Item idRoot:", selected.idPastaRootColegio);
                    inadimplente.setidRootGoogleDrive(selected.idPastaRootColegio);
                    setIdLogoGoogleDrive(inadimplente.getidRootGoogleDrive());
                    setErrorColegio(false);
                  } else {
                    setErrorColegio(true);
                  }
                }}
                sx={{ width: "100%", fontGrid: "small" }}
                renderInput={(params) => <TextField
                  {...params}
                  label="Colegio"
                  name="lblcolegio"
                  variant="standard"
                  error={errorColegio}
                  helperText={errorColegio ? "Selecione um colégio" : ""}
                  required
                />}
                fullWidth

              />



              <TextField
                type="text"
                variant='standard'
                color='secondary'
                label="id Root"
                name="idRootInadimplente"
                value={idLogoGoogleDrive}
                sx={{/* display: "none" */ }}
                fullWidth
                disabled
              />

<TextField
      type="text"
      variant="standard"
      color="secondary"
      label="Nome do Responsável Financeiro"
      name="responsavelFinanceiro"
      sx={{ marginTop: 1 }}
      value={nomeResponsavel} // Usa o estado local como valor do campo
      onChange={(e) => {
        const value = e.target.value.trim();
        setNomeResponsavel(value); // Atualiza o estado local
        inadimplente.setnomeResponsavelFinanceiro(value); // Atualiza o objeto inadimplente

        if (value !== "") {
          setErrorNomeResponsavel(false); // Remove o erro se houver texto
        }
      }}
      onBlur={(e) => {
        if (e.target.value.trim() === "") {
          setErrorNomeResponsavel(true); // Define erro se estiver vazio
        }
      }}
      fullWidth
      required
      error={errorNomeResponsavel}
      helperText={errorNomeResponsavel ? "O nome do responsável financeiro é obrigatório" : ""}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <AccountCircle />
          </InputAdornment>
        ),
      }}
    />

              <Box sx={{ display: "flex" }}>
                <TextField
                  type="text"
                  variant='standard'
                  color='secondary'
                  label="WhatsApp"
                  onChange={e => inadimplente.setwhatsapp(e.target.value)}
                  value={inadimplente.getwhatsapp()}
                  fullWidth
                  required
                  sx={{ marginRight: 2, marginTop: 1 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <WhatsAppIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  type="text"
                  variant='standard'
                  color='secondary'
                  label="Telefone"
                  onChange={e => inadimplente.settelefone(e.target.value)}
                  value={inadimplente.gettelefone()}
                  fullWidth
                  required
                  sx={{ marginTop: 1 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocalPhoneIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box sx={{ display: "flex" }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  color='secondary'
                  options={nacionalidade}
                  defaultValue={" "}
                  onChange={(e, value) => inadimplente.setnacionalidade(value && value.label ? value.label : "brasileiro(a)")}
                  sx={{ width: "100%", fontGrid: "small", marginRight: 2, marginTop: 1 }}
                  renderInput={(params) => <TextField {...params} label="Nacionalidade" variant='standard' />}
                  fullWidth
                  required
                />
                <TextField
                  type="text"
                  variant='standard'
                  color='secondary'
                  label="RG"
                  onChange={e => inadimplente.setrg(e.target.value)}
                  value={inadimplente.getrg()}
                  sx={{ marginRight: 2, marginTop: 1 }}
                  fullWidth
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AssignmentIndIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  type="text"
                  variant='standard'
                  color='secondary'
                  label="CPF"
                  onChange={e => inadimplente.setcpf(e.target.value)}
                  value={inadimplente.getcpf()}
                  sx={{ marginTop: 1 }}
                  fullWidth
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AssignmentIndIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <TextField
                type="text"
                variant='standard'
                color='secondary'
                label="Email"
                sx={{ marginTop: 1 }}
                onBlur={e => validaEmail((e.target.value))}
                value={inadimplente.getemail()}
                fullWidth
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Box sx={{ display: "flex" }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  color='secondary'
                  options={genero}
                  defaultValue={" "}
                  onChange={(e, value) => inadimplente.setgenero(value && value.label ? value.label : " ")}
                  sx={{ width: "100%", fontGrid: "small", marginTop: 1, marginRight: 2 }}
                  renderInput={(params) => <TextField {...params} label="Genero" variant='standard' />}
                  fullWidth
                  required
                />
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  color='secondary'
                  options={estadosCivil}
                  defaultValue={" "}
                  onChange={(e, value) => inadimplente.setestadoCivil(value && value.label ? value.label : "casado(a)")}
                  sx={{ width: "100%", fontGrid: "small", marginTop: 1, marginRight: 2 }}
                  renderInput={(params) => <TextField {...params} label="Estado Civil" variant='standard' />}
                  fullWidth
                  required
                />
                <TextField
                  type="text"
                  variant='standard'
                  color='secondary'
                  label="Profissão"
                  defaultValue={" "}
                  sx={{ marginTop: 1 }}
                  onChange={e => inadimplente.setprofissao(e.target.value == "" ? "profissao ignorada" : e.target.value)}
                  value={inadimplente.getprofissao()}
                  fullWidth
                  required
                />
              </Box>
              <CepSearch />

              <TextField
                type="text"
                variant='standard'
                color='secondary'
                label="Endereço 2"
                defaultValue={" "}
                sx={{ marginTop: 1 }}
                onChange={e => inadimplente.setendereco2(e.target.value)}
                value={inadimplente.getendereco2()}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BusinessIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                color='secondary'
                options={foro}
                defaultValue={" "}
                onChange={(e, value) => inadimplente.setforo(value && value.label ? value.label : "foro")}
                sx={{ width: "100%", fontGrid: "small", marginTop: 1, marginRight: 2 }}
                renderInput={(params) => <TextField {...params} label="Foro" variant='standard' />}
                fullWidth
                required
              />
              <Box sx={{ justifyContent: "center", width: "100%" }} >
                <Button variant="outlined" onClick={cadastraInadimplente} sx={{ marginTop: 2, marginLeft: 6, justifyContent: "center", width: "35%" }} color="success" endIcon={<SendIcon />}>Cadastrar</Button>
                <Button variant="outlined" onClick={limpaform} sx={{ marginTop: 2, marginLeft: 6, justifyContent: "center", width: "35%" }} color="success" endIcon={<SendIcon />}>Limpar</Button>
              </Box>
            </Stack>
          </FormControl>
        </Box>



      </Card>
      <Divider variant="middle" sx={{ margin: 2 }} />
      {/* <CreateFolderIDInadimplente /> */}

      <Card variant="outlined">
        <Box component="bxgridCadastroAdv" sx={{
          margin: "0px",
          p: 0,
          height: '90%',
          width: '30%%',
          justifyContent: 'center',
          background: '#fff'
        }} >
          <Stack spacing={1} sx={{ margin: 2, align: "Center" }}>
            <h5>Inadimplentes Cadastrados</h5>
            <React.StrictMode>
              <StyledEngineProvider injectFirst>
                <DataGridInadimplente />
              </StyledEngineProvider>
            </React.StrictMode>
          </Stack>
        </Box>
      </Card>
    </Box>

  );

}
