import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Paper from '@mui/material/Paper';
import PaymentsIcon from '@mui/icons-material/Payments';
import SchoolIcon from '@mui/icons-material/School';
import GavelIcon from '@mui/icons-material/Gavel';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Paid from '@mui/icons-material/Paid';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Divider from '@mui/material/Divider';
import Home from '@mui/icons-material/Home';



const green = {

    50: '#E8F8F5',
    100: '#D4EFDF',
    200: '#7DCEA0',
    300: '#afb8c1',
    400: '#52BE80',
    500: '#49E295',
    600: '#229954',
    700: '#1E8449',
    800: '#196F3D',
    900: '#0B5345',
    1000: '#08362D',
    1100: '#1F2D27'

}


const data = [
    { icon: <Home />, label: 'Home', secondary: 'Escritório', to: '/' },
    { icon: <AccountCircle/>, label: 'Cadastros Administrativos', secondary: 'Escritório', to: '/TabsCadastrosIniciais' },
    { icon: <SchoolIcon />, label: 'Cadastro do Inadimplente', secondary: 'Débito', to: '/TabCadastrosInadimplente' /*},
    { icon: <PaymentsIcon />, label: 'Impressāo dos documentos', secondary: 'Débito', to: '/TabImpressaoDocumentos' */},
{ icon: <PaymentsIcon />, label: 'Débitos dos Inadimplentes', secondary: 'Débito', to: '/TabGestaoInadimplentes'},
    { icon: <Paid />, label: 'Pagamentos', to: '/TabGestaoPagamentos' },
    { icon: <GavelIcon  />, label: 'Judiciais', to: '/Judiciais' },
    { icon: <AssessmentIcon />, label: 'Relatórios Financeiros', to: '/RelatoriosFinanceiros' },

];

export default function Menu() {
    const [open, setOpen] = React.useState(true);

    return (

        <Box sx={{
            display: 'flex', 
            width: "30%", 
            justifyContent: 'Left', 
      


        }}  >
            <ThemeProvider
                theme={createTheme({
                    components: {
                        MuiListItemButton: {
                            defaultProps: {
                                disableTouchRipple: true,
                       
                            },
                        },
                    },
                    palette: {
                        mode: 'dark',
                        primary: { main: green[200] },
                        background: { paper: green[1100] },
                        
                    },
                })}
            >
                <Paper elevation={1} sx={{ width: "100%", fontSize: 12,  height: "100%" }}>
                    {open &&
                        data.map((item) => (
                            <ListItemButton
                                key={item.label}
                                sx={{ py: 0.7, maxHeight: 50, color: 'rgba(255,255,255,.8)',                
    
                            }} >
                                <ListItemIcon sx={{ mx: -1, marginRight: -2, color: green[200], fontSize: "small",   marginTop: "15px" }} >
                                    {item.icon}
                                </ListItemIcon>
                                <Link
                                    primary={item.primary}
                                    secondary={item.secondary}
                                    to={item.to}>{item.label}
                                </Link>

                            </ListItemButton>



                        ))}

                </Paper>

            </ThemeProvider>


        </Box>



    );
}