import React, { useEffect } from 'react'
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { DataGrid, GridEditInputCell } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { createContext } from 'react';
import CrudDebitoGestao from '../../Service/CrudDebitoGestao';
import Procuracao from '../googleApi/CriaContratos/mesclaDocumentosProcuracao';
import PlanilhaItem from '../googleApi/CriaContratos/mesclaDocumentosPlanilhaItem';
import Notificacao from '../googleApi/CriaContratos/mesclaDocumentosNotificacao';
import Envelope from '../googleApi/CriaContratos/mesclaDocumentosEnvelope';
import EtiquetaDestinatario from '../googleApi/CriaContratos/mesclaDocumentosEtiqueta';
import Monitoria from '../googleApi/CriaContratos/mesclaDocumentosMonitoria';
import Envelope02 from '../googleApi/CriaContratos/mesclaDocumentosEnvelope02';
import MinutaAcordo from '../googleApi/CriaContratos/mesclaDocumentosMinutaAcordo';
import Cobranca from '../googleApi/CriaContratos/mesclaDocumentosCobranca';
import Cumprimento from '../googleApi/CriaContratos/mesclaDocumentosCumprimentoSentenca';
import EtiquetaLote from '../googleApi/CriaContratos/mesclaDocumentosEtiquetaLote';
import RelatorioLote from '../googleApi/CriaContratos/mesclaDocumentosRelatorioLote';
import GeraPDF from '../googleApi/CriaContratos/mesclaDocumentosPDF';
import CrudReceberAR from '../../Service/CrudReceberAR';
import RelatorioFinanceiroLot from '../googleApi/CriaContratos/mesclaDocumentosRelatorioFinaceiroLote';

import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';

import { API_URL } from '../../constants';
import Requests from "../../api/requests";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'; // Ícone do Google Drive
import { IconButton } from '@mui/material'; // Botão de ícone do Material-UI
import { List, ListItem, Link } from '@mui/material';


//Modal
//https://backefront.com.br/como-fazer-upload-arquivo-react/#:~:text=Quando%20desenvolvemos%20formul%C3%A1rios%20em%20nossos,o%20que%20tiver%20que%20subir!


import {
  GridRowModes,
  GridToolbar,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import {
  randomCreatedDate,
  randomTraderName,
  randomId,
  randomArrayItem,
} from '@mui/x-data-grid-generator';
import { parseConfigFileTextToJson } from 'typescript';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { data } from '../impressaoDocumentos/data';
import { Impressao } from '../impressaoDocumentos/impressao';
import { ImpressaoNotificacao } from '../impressaoDocumentos/notificacao';
import Modal from '@mui/material/Modal';


pdfMake.vfs = pdfFonts.pdfMake.vfs;


// Botão de deletar
export function DeleteButton({ id, handleDelete }) {
  return (
    <Button
      variant="standard"
      color="secondary"
      startIcon={<DeleteIcon />}
      onClick={() => handleDelete(id)}
    >

    </Button>
  );
}







function gerarTodosDocumentos(tableData) {

  console.log(tableData);
  let i = 0;
  let alunos = '';

  function processarProximoItem() {
    // Verifica se ainda há elementos a serem processados
    if (i < tableData.length) {
      let item = tableData[i];
      console.log("Processando item " + i + ": ", item);


      if (item.urlRootInadimplente != null && item.urlRootInadimplente != "" && item.urlRootInadimplente.length > 0) {
        let params = {
          row: {
            nomeResponsavelFinanceiro: item.nomeResponsavelFinanceiro,
            colegio: item.colegio,
            aluno: item.aluno,
            urlRootInadimplente: item.urlRootInadimplente
          }
        };


        handleImprimirProcuracao(params, "FECHADO");
        handleImprimirEnvelope(params, "FECHADO");
        //  handleImprimirEnvelope02(params, "FECHADO");
        handleImprimirMonitoria(params, "FECHADO");
        handleImprimirNotificacao(params, "FECHADO");
        handleImprimirCobranca(params, "FECHADO");
        handleImprimirMinutaAcordo(params, "FECHADO");
        handleImprimirCumprimento(params, "FECHADO");

        // const userResponse = window.prompt("Processando item " + i + ": " + item + "Press 'OK' to continue processing the next item, or 'Cancel' to stop.");
        alunos += ("Aluno: " + i + " : " + item.aluno + "\n");



        if (tableData.length - 1 == i && i > 0) {
          alert("Todos documentos foram processados\n" + alunos);

        }
      } else {
        alert("O inadimplente: " + item.aluno + " não foi cadastrado corretamente, está sem a pasta cadastrada");
      }

      i++; // Passa para o próximo item

      // Chama a função novamente após 15 segundos
      setTimeout(processarProximoItem, 15000);
    }


  }

  // Inicia o processamento do primeiro item
  processarProximoItem();
  alert("Foram gerados todos os documentos para os alunos: \n\n" + alunos);

}





function documentosGerados(tableData) {
  let req = new Requests();
  let crudReceberAR = new CrudReceberAR();
  req.requestPUTparamDocumentosGeradosAtualizar('contrato');
  crudReceberAR.cadastraReceberARAPI(tableData);
  window.location.reload();
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

//teste 
const classeImpressao = new Impressao(data);
const documento = classeImpressao.PreparaDocumento();
const crudGestaoDebito = new CrudDebitoGestao;
/*
//notificacao
const classeImpressaoNotificacao = new ImpressaoNotificacao(data);
const documentoNotificacaoPF = classeImpressaoNotificacao.PreparaDocumento();
*/

// const rows =  crudGestaoDebito.buscaGestaoDebito();


const StyledBox = styled(Box)(({ theme }) => ({
  height: 400,
  width: '100%',
  '& .MuiDataGrid-cell--editable': {
    backgroundColor: theme.palette.mode === 'dark' ? '#376331' : 'rgb(217 243 190)',
    '& .MuiInputBase-root': {
      height: '100%',
    },
  },
  '& .Mui-error': {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
    color: theme.palette.mode === 'dark' ? '#ff4343' : '#750f0f',
  },
}));

let promiseTimeout;
function validateName(username) {
  /*const existingUsers = rows.map((row) => row.name.toLowerCase());

  return new Promise((resolve) => {
    promiseTimeout = setTimeout(() => {
      const exists = existingUsers.includes(username.toLowerCase());
      resolve(exists ? `${username} is already taken.` : null);
    }, Math.random() * 500 + 100); // simulate network latency
  });*/
}

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

function NameEditInputCell(props) {
  const { error } = props;

  return (
    <StyledTooltip open={!!error} title={error}>
      <GridEditInputCell {...props} />
    </StyledTooltip>
  );
}

function renderEditName(params) {
  //console.log("ACOLA");
  //console.log(params.row);
  //selectTableData.push(params.row);
  //console.log(selectTableData);
  return <NameEditInputCell {...params} />;
}



export default function ImpressaoDocumentos() {
  const preProcessEditCellProps = async (params) => {
    const errorMessage = await validateName(params.props.value.toString());
    // alert('aqui');
    // console.log()
    return { ...params.props, error: errorMessage };
  };

  const [cardFile, setCardFile] = useState();
  const handleUploadFile = (e: any) => console.log(e.target.files[0]);

  const [procuracaoIco, setPocuracaoIco] = React.useState("green");

  // Função de deletar dentro do componente
  const handleDelete = (id) => {
    fetch(`${API_URL}debitosGestao_notificacao/${id}`, {
      method: 'DELETE',
    })
      .then(() => {
        // Remover o registro localmente após a exclusão
        setTableData((prevData) => prevData.filter((row) => row.id !== id));
      })
      .catch((error) => console.error('Erro ao deletar:', error));
  };


  const columns = [
    {
      field: 'deletar',
      headerName: 'Deletar',
      width: 80,
      renderCell: (params) => (
        <DeleteButton id={params.row.id} handleDelete={handleDelete} />
      ),
    },
    {
      field: 'Contratos',
      headerName: 'Gerar Todos Contratos',
      width: 80,
      renderCell: (params) => {
        // Criar um array com o objeto da linha selecionada
        const tableDataRowArray = [params.row]; // Apenas a linha selecionada como um array

        return (
          <IconButton
            onClick={() => gerarTodosDocumentos(tableDataRowArray)} // Passar o array com a linha selecionada
            color="primary"
          >
            <CloudUploadIcon />
          </IconButton>
        );
      },
    },
    {
      field: 'urlRootInadimplente',
      headerName: 'Pasta Google Drive',
      type: 'string',
      width: 100,
      renderCell: (params) => {
        const url = params.value; // Obter o valor da URL

        // Verifica se a URL da pasta está disponível
        if (url) {
          return (
            <IconButton
              onClick={() => window.open(`https://drive.google.com/drive/folders/${url}`, '_blank')}
              color="primary"
            >
              <DriveFolderUploadIcon /> {/* Ícone do Google Drive */}
            </IconButton>
          );
        } else {
          return (
            <Typography color="textSecondary" variant="body2">
              Indisponível
            </Typography>
          );
        }
      },
    },
    {
      field: 'colegio',
      headerName: 'Colégio',
      width: 250,
      editable: true,
      preProcessEditCellProps,
      renderEditCell: renderEditName,

    },
    {
      field: 'nomeResponsavelFinanceiro',
      headerName: 'Responsavel Financeiro',
      width: 250,
      editable: false,
      preProcessEditCellProps,
      renderEditCell: renderEditName,
    },
    {
      field: 'aluno',
      headerName: 'Aluno',
      width: 250,
      editable: false,
      preProcessEditCellProps,
      renderEditCell: renderEditName,
    },


    {
      field: 'procuracao',
      headerName: 'Documentos',
      width: 500,
      renderCell: (params) => (
        <List dense>

          <ListItem key={params.row.urlRootInadimplente}  >
            <Link href={params.row.urlRootInadimplente} target="_blank" rel="noopener"
              sx={{
                color: 'black', textDecorationColor: 'black', '&:hover': {
                  color: 'black', // Altera a cor do texto ao passar o mouse
                  borderBottom: '1px solid black', // Altera a cor da borda ao passar o mouse
                }, '&:visited': {
                  color: 'black', // Altera a cor do texto ao passar o mouse
                  borderBottom: '1px solid black', // Altera a cor da borda ao passar o mouse
                },
              }}>

              Planilha: {params.row.urlRootInadimplente}
            </Link>
          </ListItem>
          <ListItem key={params.row.urlRootInadimplente} disablePadding>
            <Link href={params.row.urlRootInadimplente} target="_blank" rel="noopener"
              sx={{
                color: 'black', textDecorationColor: 'black', '&:hover': {
                  color: 'black', // Altera a cor do texto ao passar o mouse
                  borderBottom: '1px solid black', // Altera a cor da borda ao passar o mouse
                }, '&:visited': {
                  color: 'black', // Altera a cor do texto ao passar o mouse
                  borderBottom: '1px solid black', // Altera a cor da borda ao passar o mouse
                },
              }}>

              Notificaçāo:  {params.row.urlRootInadimplente}
            </Link>
          </ListItem>
          <ListItem key={params.row.urlRootInadimplente} disablePadding>
            <Link href={params.row.urlRootInadimplente} target="_blank" rel="noopener"
              sx={{
                color: 'black', textDecorationColor: 'black', '&:hover': {
                  color: 'black', // Altera a cor do texto ao passar o mouse
                  borderBottom: '1px solid black', // Altera a cor da borda ao passar o mouse
                }, '&:visited': {
                  color: 'black', // Altera a cor do texto ao passar o mouse
                  borderBottom: '1px solid black', // Altera a cor da borda ao passar o mouse
                },
              }}>

              Monitoria:   {params.row.urlRootInadimplente}
            </Link>
          </ListItem>
          <ListItem key={params.row.urlRootInadimplente} disablePadding>
            <Link href={params.row.urlRootInadimplente} target="_blank" rel="noopener"
              sx={{
                color: 'black', textDecorationColor: 'black', '&:hover': {
                  color: 'black', // Altera a cor do texto ao passar o mouse
                  borderBottom: '1px solid black', // Altera a cor da borda ao passar o mouse
                }, '&:visited': {
                  color: 'black', // Altera a cor do texto ao passar o mouse
                  borderBottom: '1px solid black', // Altera a cor da borda ao passar o mouse
                },
              }}>
              Monitoria:  
              {params.row.urlRootInadimplente}
            </Link>
          </ListItem>
        </List>
      ),
    },


  ];

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    return () => {
      clearTimeout(promiseTimeout);
    };
  }, []);


  const [tableData, setTableData] = useState([])
  useEffect(() => {
    fetch(API_URL + "debitosGestao")
      .then((data) => data.json())
      .then((data) => setTableData(data))

  }, [])



  // Função para lidar com a mudança nas linhas selecionadas
  const handleSelectionModelChange = (selectedRows) => {
    // selectedRows contém os índices das linhas selecionadas
    // Use esses índices para obter os dados das linhas selecionadas
    console.log(selectedRows);
    // const selectedData = selectedRows.map((index) => tableData[index]);
    // Atualize a variável tableData com os dados das linhas selecionadas
    // setTableData(selectedData);
  };


  return (
    <StyledBox>

      <Stack spacing={2} direction="row">
        <Button variant="contained" color="success" onClick={() => gerarTodosDocumentos(tableData)} >Gerar Documentos</Button>
      </Stack>


      <DataGrid
        // sx ={{height:'900px'}}
        rows={tableData}
        columns={columns}
        pageSize={8}
        getRowHeight={() => 'auto'}
        sx={{
          textDecorationColor: 'black',
          '& .MuiDataGrid-cell': {
            py: 1, // Adjust vertical padding as needed
            alignItems: 'flex-start',
          },
          '& .MuiListItem-root': {
            display: 'list-item', // Ensure list items are displayed correctly
          },
        }}

      />
    </StyledBox>
  );
}

let selectTableData = new Array;

const salvar = (params, open) => {
  console.log(params.row);

}




