
import { FormControl } from '@mui/base/FormControl';
import Button from '@mui/material/Button';
import { TextField, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { StyledEngineProvider } from '@mui/material/styles';
import React, { useState, useEffect } from 'react'
import Divider from '@mui/material/Divider';
import SendIcon from '@mui/icons-material/Send';
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import Autocomplete from '@mui/material/Autocomplete';
import DataGridAluno from '../../components/datagrids/DataGridCadastroAluno.js';
import { Aluno } from '../../DTO/Aluno.js';
import CrudAluno from '../../Service/CrudAluno.js';

import AccountCircle from '@mui/icons-material/AccountCircle';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import InputAdornment from '@mui/material/InputAdornment';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br'; // Import the Portuguese locale if needed

const aluno = new Aluno();
let crudsAluno = new CrudAluno();




const genero = [
  { label: 'masculino', id: 1 },
  { label: 'feminino', id: 2 }
]

function limpaform() {
  Array.from(document.querySelectorAll("input")).forEach(
    input => (input.value = "")
  );

}


export default function CadastroAluno() {
  const [colegios, setColegios] = useState([]); // Initialize with empty array
  const [inadimpletes, setInadimplentes] = useState([]); // Initialize with empty array
  const [selectedColegioName, setSelectedColegioName] = useState(null);

  const cadastraAluno = () => {
    if (validateForm()) {
      crudsAluno.cadastraAlunoAPI(aluno);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };
  
  const fetchInadimplentes = async (colegioName) => {
    // Fetch data and set it to the inadimplentes state
    const inadimplenteData = await crudsAluno.buscaInadimplenteAPI(colegioName);
    if (inadimplenteData) {
      console.log("INADIMPLENTES");

      console.log(inadimplenteData);
      setInadimplentes(inadimplenteData);
    }
  };
  //USE EFFECT - BUSCA COLEGIOS A PARTIR DE UM NOME SELECIONADO 
  useEffect(() => {
    if (selectedColegioName) {
      fetchInadimplentes(selectedColegioName);
    }
  }, [selectedColegioName]);
  //USE EFFECT - BUSCA COLEGIOS
  useEffect(() => {
    async function fetchColegios() {
      const colegiosData = await crudsAluno.buscacolegioAPI();
      if (colegiosData) {
        setColegios(colegiosData);
      }
    }
    fetchColegios();

  }, []); // Run once on component mount

  const [errors, setErrors] = useState({
    colegio: '',
    inadimplente: '',
    nomeAluno: '',
  });

  
  const validateForm = () => {
    let isValid = true;
    const newErrors = { colegio: '', inadimplente: '', nomeAluno: '' };
  
    // Verifica se o campo "Colegio" foi selecionado
    if (!aluno.getcolegioAluno()) {
      newErrors.colegio = 'Campo "Colegio" é obrigatório';
      isValid = false;
    }
  
    // Verifica se o campo "Inadimplente" foi selecionado
    if (!aluno.getinadimplenteAluno()) {
      newErrors.inadimplente = 'Campo "Inadimplente" é obrigatório';
      isValid = false;
    }
  
    // Verifica se o campo "Nome do Aluno" foi preenchido
    if (!aluno.getnomeAluno()) {
      newErrors.nomeAluno = 'Campo "Nome do Aluno" é obrigatório';
      isValid = false;
    }
  
    setErrors(newErrors);
    return isValid;
  };

  

  return (

    <Box sx={{
      width: '99%',
      justifyContent: "Center",
      p: 0,
      background: "#f3f6f37d",
    }}>
      <Card variant="outlined" sx={{
        width: '100%',
        justifyContent: "left",
        flexDirection: 'row',
        display: 'flex',
      }}>
        <Box sx={{
          width: '100%',
          justifyContent: "left",
          position: "relative",
          flexGrow: 2
        }} >
          <FormControl defaultValue="">
            <Stack spacing={1} sx={{ marginLeft: 5, marginRight: 5, marginTop: 5, marginBottom: 5, align: "Center" }}>
              <h2>Cadastro do Aluno</h2>
              <Autocomplete
  disablePortal
  id="combos-box-demo"
  color="secondary"
  options={colegios}
  onChange={(e, value) => {
    aluno.setcolegioAluno(value && value.label ? value.label : " ");
    setSelectedColegioName(value && value.label ? value.label : " ");
  }}
  getOptionLabel={(option) => option.label}
  sx={{ width: "100%", fontGrid: "small" }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Colegio"
      variant="standard"
      error={!!errors.colegio} // Condicionalmente exibe erro
      helperText={errors.colegio} // Exibe a mensagem de erro
    />
  )}
  fullWidth
  required
/>

<Autocomplete
  disablePortal
  id="combo-box-demo"
  color="secondary"
  options={inadimpletes}
  getOptionLabel={(option) => option.label}
  onChange={(e, value) => aluno.setinadimplenteAluno(value && value.label ? value.label : " ")}
  sx={{ width: "100%", fontGrid: "small" }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Inadimplente"
      variant="standard"
      error={!!errors.inadimplente} // Exibe erro se necessário
      helperText={errors.inadimplente} // Exibe a mensagem de erro
    />
  )}
  fullWidth
  required
/>

<TextField
  type="text"
  variant="standard"
  color="secondary"
  label="Nome do Aluno"
  onChange={e => aluno.setnomeAluno(e.target.value)}
  value={aluno.getnomeAluno()}
  fullWidth
  required
  sx={{ marginTop: 1 }}
  InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <AccountCircle />
      </InputAdornment>
    ),
  }}
  error={!!errors.nomeAluno} // Exibe erro se necessário
  helperText={errors.nomeAluno} // Exibe a mensagem de erro
/>

              <Box sx={{ display: "flex" }}>
                <TextField
                  type="text"
                  variant='standard'
                  color='secondary'
                  label="RGM ou Código do Aluno"
                  onChange={e => aluno.setcodigoAluno(e.target.value)}
                  value={aluno.setcodigoAluno()}
                  fullWidth
                  required
                  sx={{ marginTop: 1, marginRight: 2 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AssignmentIndIcon />
                      </InputAdornment>
                    )
                  }}
                />
                <TextField
                  type="text"
                  variant='standard'
                  color='secondary'
                  label="Turma"
                  onChange={e => aluno.setturmaAluno(e.target.value)}
                  value={aluno.getturmaAluno()}
                  fullWidth
                  required
                  sx={{ marginTop: 1, marginRight: 2 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AssignmentIndIcon />
                      </InputAdornment>
                    )
                  }}
                />
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  color='secondary'
                  options={genero}
                  defaultValue={" "}
                  onChange={(e, value) => aluno.setgeneroAluno(value && value.label ? value.label : " ")}
                  sx={{ width: "100%", fontGrid: "small", marginTop: 1 }}
                  renderInput={(params) => <TextField {...params} label="Genero " variant='standard' />}
                  fullWidth
                  required
                />

              </Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Data de ensino"
                  id="dataEnsino"
                  format= "YYYY"
              //    onChange={(value) => aluno.setdataEnsino(dayjs(value).format('DD/MM/YYYY'))}
              onChange={(value) => aluno.setdataEnsino(dayjs(value).format('YYYY'))}
                />
              </LocalizationProvider>

              <Box sx={{ justifyContent: "center", width: "100%" }} >
                <Button variant="outlined" onClick={cadastraAluno} sx={{ marginTop: 2, marginLeft: 6, justifyContent: "center", width: "35%" }} color="success" endIcon={<SendIcon />}>Cadastrar</Button>
                <Button variant="outlined" onClick={limpaform} sx={{ marginTop: 2, marginLeft: 6, justifyContent: "center", width: "35%" }} color="success" endIcon={<SendIcon />}>Limpar</Button>
              </Box>
            </Stack>
          </FormControl>
        </Box>

      </Card>
      <Divider variant="middle" sx={{ margin: 2 }} />
      <Card variant="outlined">
        <Box component="bxgridCadastroAdv" sx={{
          margin: "0px",
          p: 0,
          height: '90%',
          width: '100%',
          justifyContent: 'center',
          background: '#fff'
        }} >
          <Stack spacing={1} sx={{ margin: 2, align: "Center" }}>
            <h2>Colégios Cadastrados</h2>
            <React.StrictMode>
              <StyledEngineProvider injectFirst>
                <DataGridAluno />
              </StyledEngineProvider>
            </React.StrictMode>
          </Stack>
        </Box>
      </Card>
    </Box>
  );
}
