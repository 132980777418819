import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import SortIcon from '@mui/icons-material/Sort';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import Debito from '../../DTO/Debito.js';
import CrudDebito from '../../Service/CrudDebito.js';
import ItemDebito from '../../DTO/ItemDebito.js';
import SendIcon from '@mui/icons-material/Send';

import { FormControl } from '@mui/base/FormControl';
import { TextField, Stack } from '@mui/material';
import Divider from '@mui/material/Divider';
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import Autocomplete from '@mui/material/Autocomplete';
import MesclaSheets from '../googleApi/CriaContratos/mesclaDocumentosPlanilha.js';
import { API_URL } from '../../constants';

import {
  GridRowModes,
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import {
  randomCreatedDate,
  randomTraderName,
  randomId,
  randomArrayItem,
} from '@mui/x-data-grid-generator';
import { parseConfigFileTextToJson } from 'typescript';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

// Icones personalizados
export function SortedDescendingIcon() {
  return <ExpandMoreIcon className="icon" />;
}

export function SortedAscendingIcon() {
  return <ExpandLessIcon className="icon" />;
}

export function UnsortedIcon() {
  return <SortIcon className="icon" />;
}

const roles = ['Market', 'Finance', 'Development'];
const randomRole = () => {
  return randomArrayItem(roles);
};

const debito = new Debito();
const crudDebito = new CrudDebito();
const initialRows = [
  {
    id: 1,
    ordem: "1",
    descricao: "Mensalidade",
    dataVencimento: "10/05/2022",
    valor: 600,
    valorDebito: 800
  },
];

let indiceMes, indiceAtual;
function setIndiceMes(value) {
  indiceMes = value;
}
function getIndiceMes() {
  return indiceMes;
}

function setIndiceAtual(value) {
  indiceAtual = value;
}
function getIndiceAtual() {
  return indiceAtual;
}

function limpaform() {
  Array.from(document.querySelectorAll("input")).forEach(
    input => (input.value = "")
  );
}

function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [...oldRows, { id, name: '', age: '', isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Adicione mais um item
      </Button>
    </GridToolbarContainer>
  );
}

export default function DataGridCalculoDebito() {
  const [rows, setRows] = useState(initialRows);
  const [rowModesModel, setRowModesModel] = useState({});

  // Estados para o Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  // Funções para controlar o Snackbar
  const handleOpenSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
      // alert("aqui");
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    // alert("editei");
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    // alert("Clique na linha editada para atualizar o valor");
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////
  const [colegios, setColegios] = useState([]); // Initialize with empty array
  const [inadimpletes, setInadimplentes] = useState([]); // Initialize with empty array
  const [alunos, setAlunos] = useState([]); // Initialize with empty array
  const [selectedColegioName, setSelectedColegioName] = useState(null);
  const [selectedAlunosName, setSelectedAlunosName] = useState(null);

  const fetchInadimplentes = async (colegioName) => {
    // Fetch data and set it to the inadimplentes state
    const inadimplenteData = await crudDebito.buscaInadimplenteAPI(colegioName);
    if (inadimplenteData) {
      console.log("INADIMPLENTES");
      console.log(inadimplenteData);
      setInadimplentes(inadimplenteData);
    }
  };

  useEffect(() => {
    if (selectedColegioName) {
      fetchInadimplentes(selectedColegioName);
    }
  }, [selectedColegioName]);

  const fetchAlunos = async (inadimplenteNome) => {
    // Fetch data and set it to the inadimplentes state
    const alunosData = await crudDebito.requestGETparamAlunosTextfield(inadimplenteNome);
    console.log("FETCH ALUNOS");
    console.log(alunosData);
    if (alunosData) {
      setAlunos(alunosData);
    }
  };

  const [idLogoGoogleDrive, setIdLogoGoogleDrive] = useState('');

  useEffect(() => {
    if (selectedAlunosName) {
      fetchAlunos(selectedAlunosName);
    }
  }, [selectedAlunosName]);

  useEffect(() => {
    async function fetchColegios() {
      const colegiosData = await crudDebito.buscacolegioAPI();
      if (colegiosData) {
        setColegios(colegiosData);
      }
    }
    fetchColegios();
  }, []); // Run once on component mount
  /////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleSaveClickTotal = async () => {
    // Exibe a mensagem de "Aguarde, gerando o relatório..." com severidade "success"
    handleOpenSnackbar('Aguarde, gerando o relatório...', 'success');

    try {
      // Crie uma cópia das linhas para evitar manipulação direta do estado
      const updatedRows = [...rows];
      console.log(debito.getcolegio());
      console.log(debito.getnomeResponsavelFinanceiro());
      console.log(debito.getaluno());
      console.log("DEBITO");
      console.log(updatedRows);
      const col = debito.getcolegio();
      const resp = debito.getnomeResponsavelFinanceiro();
      const alu = debito.getaluno();

      // Loop através de cada linha editada e envie os dados para a API
      updatedRows.forEach((updatedRow) => {
        const itemDebito = new ItemDebito();
        itemDebito.setid(updatedRow.id);
        itemDebito.setordem(updatedRow.ordem);
        itemDebito.setdescricao(updatedRow.descricao);
        itemDebito.setdataVencimento(updatedRow.dataVencimento);
        itemDebito.setvalor(updatedRow.valor); // primitivo
        //  itemDebito.setindiceVencimento(updatedRow.setindiceVencimento);
        itemDebito.setindiceAtual(updatedRow.indiceAtual);
        itemDebito.setindiceMes(updatedRow.indiceMes);
        itemDebito.setsubtotal(updatedRow.subtotal);
        itemDebito.setjuros(updatedRow.juros);
        itemDebito.settotal(updatedRow.total);
        itemDebito.setvalorDebito(updatedRow.valorDebito);
        // debito.setitemDebito([updatedRow.ordem].itemDebito);
        debito.adicionarItemDebito(itemDebito);

        // CALCULA TOTAIS
        debito.setTotal_debito_atualizado(
          parseFloat(debito.getTotal_debito_atualizado()).toFixed(2) + parseFloat(updatedRow.total).toFixed(2)
        );
        debito.setTotal_juros(
          parseFloat(debito.getTotal_juros()).toFixed(2) + parseFloat(updatedRow.juros).toFixed(2)
        );
        debito.setTotal_subtotal(
          parseFloat(debito.getTotal_subtotal()).toFixed(2) + parseFloat(updatedRow.subtotal).toFixed(2)
        );
        debito.setTotal_valor_primitivo(
          parseFloat(debito.getTotal_valor_primitivo()).toFixed(2) + parseFloat(updatedRow.valor).toFixed(2)
        ); // primitivo

        console.log(updatedRow.total);
        console.log(updatedRow.juros);
        console.log(valorSubtotal);
        console.log(updatedRow.subtotal);

        valorTotal = valorTotal + updatedRow.total;
        valorJuros = valorJuros + updatedRow.juros;
        valorSubtotal = valorSubtotal + updatedRow.subtotal;
        valorPrimitivo = valorPrimitivo + updatedRow.valor;
      });

      console.log(valorTotal);
      console.log(valorJuros);
      console.log(valorSubtotal);
      console.log(valorPrimitivo);

      if (!isNaN(valorTotal)) {
        debito.setTotal_debito_atualizado(parseFloat(valorTotal));
      }

      if (!isNaN(valorJuros)) {
        debito.setTotal_juros(parseFloat(valorJuros));
      }

      if (!isNaN(valorSubtotal)) {
        debito.setTotal_subtotal(parseFloat(valorSubtotal));
      }

      if (!isNaN(valorPrimitivo)) {
        debito.setTotal_valor_primitivo(parseFloat(valorPrimitivo));
      }
      console.log(debito);

      const { v4: uuidv4 } = require('uuid');
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/json');
      headers.append('GET', 'POST', 'OPTIONS');

      // Envia os dados para a API de débito
      const responseDebito = await fetch(API_URL + 'debito', {
        method: 'POST',
        body: JSON.stringify({
          id: uuidv4(),
          nomeResponsavelFinanceiro: debito.getnomeResponsavelFinanceiro(),
          colegio: debito.getcolegio(),
          aluno: debito.getaluno(),
          itemDebito: debito.getitemDebito(),
          rootURL: debito.getRootUrlInadimplente(),
          total_debito_atualizado: debito.getTotal_debito_atualizado(),
          total_valor_primitivo: debito.getTotal_valor_primitivo(),
          total_subtotal: debito.getTotal_subtotal(),
          total_juros: debito.getTotal_juros(),
        }),
        headers: headers,
      });

      // Envia os dados para a API de notificações
      const responseNotificacao = await fetch(API_URL + 'debitosGestao_notificacao', {
        method: 'POST',
        body: JSON.stringify({
          id: uuidv4(),
          nomeResponsavelFinanceiro: debito.getnomeResponsavelFinanceiro(),
          colegio: debito.getcolegio(),
          aluno: debito.getaluno(),
          urlRootInadimplente: debito.getRootUrlInadimplente(),
        }),
        headers: headers,
      });

      // Verifica se ambas as requisições foram bem-sucedidas
      if (responseDebito.ok && responseNotificacao.ok) {
        // Exibe a mensagem de sucesso
        handleOpenSnackbar('Relatório gerado com sucesso! Aguarde enquanto processamos...', 'success');
      } else {
        throw new Error('Falha ao gerar o relatório.');
      }

      // Cria uma nova instância de MesclaSheets
      const m = new MesclaSheets(col, resp, selectedAlunosName.nomeAluno);
    } catch (error) {
      console.error('Erro ao salvar os dados:', error);
      // Exibe a mensagem de erro
      handleOpenSnackbar(`Erro: ${error.message}`, 'error');
    }
  };

  let valorTotal = 0;
  let valorJuros = 0;
  let valorSubtotal = 0;
  let valorPrimitivo = 0;

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
    alert("deletei");
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };

    let _ano, _mes, _anoAtual, _mesAtual, _subtotal, total, _diaAtual, _mesAtualIndice;

    // Calcula datas
    const today = new Date();
    _ano = (updatedRow.dataVencimento).substring(6, 10); // parseInt("1070");
    _mes = (updatedRow.dataVencimento).substring(3, 5); // parseInt("10");
    _anoAtual = today.getFullYear(); // parseInt("2023");
    _mesAtual = parseInt(String(today.getMonth() + 1).padStart(2, '0')); // parseInt("07");
    _diaAtual = parseInt(String(today.getDate()).padStart(2, '0'));
    _mesAtualIndice = _diaAtual < 16 ? _mesAtual - 1 : _mesAtual;

    // Calcula os índices e faz as operações assíncronas
 /*   Promise.all([
      fetch(API_URL + "indices/" + _ano + "/" + parseInt(_mes)).then((response) => response.json()),
      fetch(API_URL + "indices/" + _anoAtual + "/" + parseInt(_mesAtualIndice)).then((response) => response.json())
    ]).then(([indiceMesData, indiceAtualData]) => {
      let _indiceMes = parseFloat(indiceMesData.indice.replace(",", "."));
      let _indiceAtual = parseFloat(indiceAtualData.indice.replace(",", "."));
      let _valor = parseFloat(updatedRow.valor);
      let _juros; */

      Promise.all([
        fetch(API_URL + "indices/" + _ano + "/" + parseInt(_mes))
          .then((response) => response.json())
          .catch(() => { throw new Error('Erro ao buscar o índice para o mês ' + _mes + ' e ano ' + _ano); }),
        
        fetch(API_URL + "indices/" + _anoAtual + "/" + parseInt(_mesAtualIndice))
          .then((response) => response.json())
          .catch(() => { throw new Error('Erro ao buscar o índice para o mês atual ' + _mesAtualIndice + ' e ano ' + _anoAtual); })
      ])
      .then(([indiceMesData, indiceAtualData]) => {
        let mensagensErro = [];
      
        if (!indiceMesData || !indiceMesData.indice) {
          mensagensErro.push('Índice não encontrado para o mês ' + _mes + ' e ano ' + _ano + '. Favor cadastrar.');
        }
        
        if (!indiceAtualData || !indiceAtualData.indice) {
          mensagensErro.push('Índice não encontrado para o mês atual ' + _mesAtualIndice + ' e ano ' + _anoAtual + '. Favor cadastrar.');
        }
      
        if (mensagensErro.length > 0) {
          alert(mensagensErro.slice(0, 2).join("\n")); // Exibe até 2 mensagens
          return; // Interrompe a execução do código se houver erro
        }
      
        // Converte os índices para número
        let _indiceMes = parseFloat(indiceMesData.indice.replace(",", "."));
        let _indiceAtual = parseFloat(indiceAtualData.indice.replace(",", "."));
        
        let _valor = parseFloat(updatedRow.valor);
        let _juros;
      
     

      // Calcula o subtotal
      _subtotal = (_valor / _indiceMes) * _indiceAtual;
      updatedRow.subtotal = _subtotal;

      // Calcula os juros
      _juros = (parseFloat(_anoAtual) - parseFloat(_ano)) * 12;
      _juros = (_juros + (_mesAtual) - (_mes));
      _juros = (_juros / 100) * _subtotal;
      updatedRow.juros = parseFloat(_juros.toFixed(2));

      // Calcula o total
      total = _subtotal + _juros;
      updatedRow.total = parseFloat(total.toFixed(2));

      // Atualiza os índices e outros campos na linha
      updatedRow.indiceMes = indiceMesData.indice;
      updatedRow.indiceAtual = indiceAtualData.indice;

      // Chama setRows aqui após o cálculo
      setRows((prevRows) => prevRows.map((row) => (row.id === newRow.id ? updatedRow : row)));

      console.log(updatedRow);
    })   // Calcula os juros ou faça outros tratamentos

  .catch(error => {
    // Exibe a mensagem de erro na tela
    alert(error.message);
  });
  

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  
  const currencyFormatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const columns = [
    {
      field: 'ordem',
      headerName: 'Ordem',
      width: 80,
      editable: true
    },
    {
      field: 'descricao',
      headerName: 'Título',
      type: 'string',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      editable: true,
      valueOptions: ['Mensalidade', 'Uniforme', 'Vendas', 'Atividade Extracurricular', 'Papelaria', 'Material Didático'],
    },
    {
      field: 'dataVencimento',
      headerName: 'Data de Vencimento',
      type: 'String',
      width: 150,
      editable: true,
    },
    {
      field: 'valor',
      headerName: 'Valor Primitivo',
      type: 'number',
      width: 100,
      editable: true,
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return currencyFormatter.format(value);
      },
    },
    {
      field: 'indiceMes',
      headerName: 'Indice do Mês',
      width: 100,
      editable: false,
      type: 'number',
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'indiceAtual',
      headerName: 'Indice Atual',
      width: 100,
      editable: false,
      type: 'number',
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'subtotal',
      headerName: 'SubTotal',
      width: 100,
      editable: false,
      type: 'number',
      headerClassName: 'super-app-theme--header',
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return currencyFormatter.format(value);
      },
    },
    {
      field: 'juros',
      headerName: 'Juros',
      width: 100,
      editable: false,
      type: 'number',
      headerClassName: 'super-app-theme--header',
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return currencyFormatter.format(value);
      },
    },
    {
      field: 'total',
      headerName: 'Total',
      width: 100,
      editable: false,
      type: 'number',
      headerClassName: 'super-app-theme--header',
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return currencyFormatter.format(value);
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
        '& .super-app-theme--header': {
          backgroundColor: '#F9CEC4',
        },
      }}
    >
      <Card variant="outlined" sx={{
        width: '100%',
        justifyContent: "left",
        flexDirection: 'row',
        display: 'flex',
      }} >
        <Box sx={{
          width: '50%',
          height: '300px' ,
          justifyContent: "left",
          position: "relative",
          flexGrow: 2
        }} >
          <FormControl defaultValue="">
            <Stack spacing={1} sx={{ marginLeft: 1, marginRight: 1, marginTop: 1, marginBottom: 1, align: "Center" }}>
              <Autocomplete
                disablePortal
                id="colegio_tf"
                color="secondary"
                name="colegio_tf"
                options={colegios} // Use colegios array as options
                // value={selectedColegioName} // Use selected colegio's name
                getOptionLabel={(option) => option.label}
                onChange={(e, value) => {
                  debito.setcolegio(value && value.label ? value.label : " "); // Set colegio ID in your state
                  setSelectedColegioName(value && value.label ? value.label : " "); // Set selected colegio's name or null if deselected
                }}
                // Display colegio names
                sx={{ width: "100%", fontGrid: "small" }}
                renderInput={(params) => <TextField {...params} label="Colegio" name="colegio" variant='standard' />}
                fullWidth
                required
              />

              <Autocomplete
                disablePortal
                id="inadimplente_tf"
                color="secondary"
                name="inadimplente_tf"
                options={inadimpletes}
                onChange={(e, value) => {
                  debito.setnomeResponsavelFinanceiro(value && value.label ? value.label : " "); // Set colegio ID in your state
                  console.log("SELECIONEI ESSA");
                  console.log(value);
                  // console.log(value.nomeAluno);

                  setSelectedAlunosName(value && value.label && value.nomeAluno && value.colegio ? {
                    nomeInadimplente: value.label,
                    nomeAluno: value.nomeAluno,
                    colegio: value.colegio
                  } : " "); // Set selected colegio's name or null if deselected
                }}
                onBlur={e =>
                  inadimpletes.forEach(item => {
                    if (item.label === e.target.value) {
                      console.log("Item ID:", item.id);
                      console.log("Item Label:", item.label);
                      console.log("Item idRoot:", item.idPastaInadimplenteGoogleDrive);
                      debito.setRootUrlInadimplente(item.idPastaInadimplenteGoogleDrive);
                      console.log(debito);
                      setIdLogoGoogleDrive(item.idPastaInadimplenteGoogleDrive);
                    }
                  })
                }
                getOptionLabel={(option) => option.label} // Adjust this based on your data structure
                sx={{ width: "100%", fontGrid: "small" }}
                renderInput={(params) => <TextField {...params} name="inadimplente" label="Inadimplente" variant='standard' />}
                fullWidth
                required
              />
              <TextField
                type="text"
                variant='standard'
                id="idRootInadimplente"
                color='secondary'
                label="id Root"
                name="idRootInadimplente"
                value={idLogoGoogleDrive}
                fullWidth
                sx={{ display: "none" }}
                required
                disabled
              />
              <Autocomplete
                disablePortal
                id="alunos_tf"
                color="secondary"
                name="alunos_tf"
                options={alunos}
                getOptionLabel={(option) => option.label} // Adjust this based on your data structure
                onChange={(e, value) => debito.setaluno(value && value.label ? value.label : " ")}
                sx={{ width: "100%", fontGrid: "small" }}
                renderInput={(params) => <TextField {...params} name="alunos" label="Aluno" variant='standard' />}
                fullWidth
                required
              />
            </Stack>
          </FormControl>
        </Box>
        
      </Card>
      <Divider variant="middle" sx={{ margin: 1 }} />
      <div style={{ height: '100%', width: '100%' }}>
        <Stack spacing={1} sx={{ marginLeft: 0, marginRight: 0, marginTop: 1, marginBottom: 1, align: "Center" }}>
          <h3 sx={{ p: 1 }}>Planilha de Débitos</h3>
          <DataGridPremium
            rows={rows}
            columns={columns}
            sx={{ height: '500px' }}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            slots={{
              toolbar: EditToolbar,
              //toolbar: GridToolbar,
              columnSortedDescendingIcon: SortedDescendingIcon,
              columnSortedAscendingIcon: SortedAscendingIcon,
              columnUnsortedIcon: UnsortedIcon
            }}
            slotProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            initialState={{
              aggregation: {
                model: {
                  subtotal: 'sum',
                  valor: 'sum',
                  juros: 'sum',
                  total: 'sum'
                },
              },
            }}
          />
        </Stack>
      </div>

      <FormControl defaultValue="">
  <Stack spacing={1} sx={{ marginLeft: 1, marginRight: 5, marginTop: 1, marginBottom: 1 }}>
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
      <Button
        variant="contained"
        onClick={handleSaveClickTotal}
        sx={{ marginTop: 2, marginLeft: 1, width: "25%" }}
        color="success"
        endIcon={<SendIcon />}
      >
        Cadastrar
      </Button>
      {/* <MesclaSheets /> */}
    </Box>
  </Stack>
</FormControl>

      {/* Componente Snackbar para mensagens */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
